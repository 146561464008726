import React from "react";
import { Header } from "../components/header";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { maxMedia, MaxWidth, minMedia, PAGE_WIDTH, READ_WIDTH } from "../utils/style";
import { useStaticQuery, graphql, Link } from "gatsby";
import RTF from "../components/rtf";
import { CaptionText } from "../components/presse-kontakt";
import { format } from "date-fns";
import { slugify } from "../utils/slugify";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Disqus } from "gatsby-plugin-disqus";
import { ROOT } from "../utils/const";

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
    allContentfulBlog(sort: {fields: [releaseDate], order: [DESC]}) {
        nodes {
          id
          title
          content {
            json
          }
          extract {
            extract
          }
          releaseDate
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Blog" />
      <Header small backgroundImg={require("../images/blog.jpg")}></Header>
      <MaxWidth greyGradient maxWidth={READ_WIDTH}>
        <Div>
          <h1>Blog</h1>

          {(data.allContentfulBlog.nodes || []).map(x => (
            <BlogPreview {...x} />
          ))}
        </Div>
        {/* <RTF content={data.contentfulHtml.content.json} /> */}
      </MaxWidth>
    </Layout>
  );
};

const Div = styled.div`
  position: relative;
  z-index: 1;
  margin-top: -12rem;
  background: white;
  padding: 2rem;
  ${maxMedia.phone`
    padding: 1rem;
  `}

  .blog-preview {
    display: block;
    padding-bottom: 1rem;
    margin-top: 3rem;

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
`;

const BlogPreview = node => {
  return (
    <>
      <Link className="blog-preview" to={`/blog/${slugify(`${node.title}-${node.id}-${`${node.releaseDate}`.replace(/T.*/, "")}`)}`}>
        <div className="text">
          <h3 style={{ marginBottom: 0 }}>{node.title}</h3>
          <CaptionText>{format(node.releaseDate, "YYYY-MM-DD")}</CaptionText>
          <div style={{ marginTop: "1rem" }}>
            <ReactMarkdown>{node.extract.extract}</ReactMarkdown>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Blog;
